.bg-salmon {
  background-color: salmon;
  color: white;
}

.btn-circle {
  float: right;
  width: 20px !important;
  height: 7px !important;
  padding: 6px 0px !important;
  border-radius: 15px !important;
  text-align: center !important;
  font-size: 12px !important;
  line-height: 1.42857 !important;
  border: 1px solid black !important;
}