button{
    background-color: #0093E9;
    color: white;
    border: none;
    border-radius: "2px";
   
   
}

button:hover{
    background-color: #024e79;
}