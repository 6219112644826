/* .file-card{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #edf2f7;
    min-width: auto;
    max-width: auto;
    padding: 1em;
    border:1px dashed #cbd5e0;
    flex-direction: column;
} */

.file-inputs{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.inputdata{
    position: relative;
    width: 150px;
    height: 32px;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
}

.buttondata{
position: absolute;
top:0px;
left: 0px;
width: 100%;
height:92%;
z-index: 1;
display: flex;
justify-content: center;
align-items: center;
color: #fff;
background-color: #f55e30;
font-size: 1.1rem;
cursor: pointer;
border-radius: 4px;
border: none;
outline: none;
box-shadow: 0px 8px 24px rgba(149,157,165,0.5);

}

i{
    width: 1.5rem;
    height: 1.2rem;
    padding: 0.4rem;
    background-color: #fff;
    color:#f55e30;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.8em;
    font-size: 0.8em;
    cursor: pointer;

}
